import { Controller } from "stimulus"

export default class extends Controller {
  toggle(event) {
    const elementToHidden = document.querySelector(`${event.target.dataset.groupingSelector}:not(${event.target.dataset.elementToShow}`);
    const elementToShow = document.querySelector(event.target.dataset.elementToShow);
    elementToHidden.classList.add('hidden');
    elementToShow.classList.remove('hidden');
  }
}
