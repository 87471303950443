import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() { return [ "container" ] }

  open() {
    this.containerTarget.classList.remove('hidden')
  }

  close() {
    this.containerTarget.classList.add('hidden')
  }
}