import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.changeStatusDisabled()
  }

  changeStatusDisabled() {
    const predefinedStatus = document.querySelector('[name="predefined_status"]:checked').value

    if (predefinedStatus === 'none') {
      document.querySelectorAll('[name="status[]"]').forEach((el) => el.removeAttribute('disabled'))
    } else {
      document.querySelectorAll('[name="status[]"]').forEach((el) => el.setAttribute('disabled', true))
    }
  }
}
