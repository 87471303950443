import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() { return [ "quantityInput", "styleInput", "pathInput" ] }


  generateQRCode(e) {
    const quantity = this.quantityInputTarget.value;
    let url = `${e.target.dataset.url}?quantity=${quantity}`
    url += this.hasStyleInputTarget ? `&qrcode_style=${this.styleInputTarget.value}` : "";
    url += this.hasPathInputTarget ? `&path=${this.pathInputTarget.value}` : "";

    window.open(url, '_blank').focus();
  }
}
