import { Controller } from "stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static get values() { return { url: String } }

  connect() {
    this.element.addEventListener('change', () => {
      if (!this.element.value) return;
      const nextIndex = document.querySelectorAll('.product-row').length + 1;
      get(`${this.urlValue}?product_id=${this.element.value}&next_index=${nextIndex}`, {
        responseKind: "html"
      }).then((response) => {
        response.html.then((html) => {
          this.element.remove(this.element.options.selectedIndex);
          document.querySelector('#order-items').insertAdjacentHTML('beforeend', html);
          document.dispatchEvent(new Event('orders/edit:recalculateSubTotal'));
          document.dispatchEvent(new Event('orders/show:recalculateTaxes'));
        });
      });
    });
  }
}
