import { Controller } from "stimulus"
import priceFormat from 'jquery-price-format'

export default class extends Controller {
  static get targets() { return [ "field" ] }
  
  connect() {
    $(this.fieldTarget).priceFormat({
      prefix: 'R$ ',
      thousandsSeparator: '',
      clearSuffix: true,
      clearPrefix: true,
    });
  }
}
