import { Controller } from "stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static get values() { return { url: String } }

  connect() {
    this.element.addEventListener('click', () => {
      if(confirm('Tem certeza que deseja remover esse produto?')) {
        get(this.urlValue, {
          responseKind: "html"
        }).then((response) => {
          response.html.then((html) => {
            document.querySelector('select#product').insertAdjacentHTML('beforeend', html);
            this.element.closest('.product-row').remove();
            document.dispatchEvent(new Event('orders/edit:recalculateSubTotal'));
            document.dispatchEvent(new Event('orders/show:recalculateTaxes'));
          });
        });
      }
    });
  }
}
