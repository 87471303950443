import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() { return [ "totalTax", "totalShipping", "administrativeFeeTotal", "total" ] }

  connect() {
    document.addEventListener('orders/show:recalculateTaxes', this.calculateTotal.bind(this));
  }

  calculateTotal() {
    const totalTax = parseFloat(this.totalTaxTarget.value);
    const totalShipping = parseFloat(this.totalShippingTarget.value);
    const administrativeFeeTotal = parseFloat(this.administrativeFeeTotalTarget.value);
    const subtotal = parseFloat(document.querySelector('#subtotal-value').innerHTML.replace('R$ ', ''));

    this.totalTarget.innerHTML = `R$ ${(totalTax + totalShipping + administrativeFeeTotal + subtotal).toFixed(2)}`;
  }
}
