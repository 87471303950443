import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() { return [ "quantity", "price", "total", "package" ] }

  calculateTotal() {
    const quantity = parseInt(this.quantityTarget.value, 10);
    const price = parseFloat(this.priceTarget.value);
    this.totalTarget.innerHTML = `R$ ${(quantity * price).toFixed(2)}`;
    document.dispatchEvent(new Event('orders/edit:recalculateSubTotal'));
  }

  selectPackage() {
    const amount = this.packageTarget.options[this.packageTarget.selectedIndex].dataset.price;
    this.priceTarget.value = amount;
    setTimeout(() => this.calculateTotal());
  }
}
