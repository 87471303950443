import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() { return [ "file" ] }
  
  fileChanged() {

    var file_data = this.fileTarget.files[0];
    var form_data = new FormData();
    form_data.append('photo[file]', file_data);
    $.ajax({
      url: '/photos',
      dataType: 'html',
      cache: false,
      contentType: false,
      processData: false,
      data: form_data,
      type: 'post',
      success: (html) => $('turbo-frame#photos').append(html)
    });
  }
}
