import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() { return [ "container" ] }
  static get values() { return { latitude: Number, longitude: Number, name: String } }

  connect() {
    var map = L.map(this.containerTarget).setView([this.latitudeValue, this.longitudeValue], 16);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);
    
    const link = `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${this.latitudeValue},${this.longitudeValue}`
    L.marker([this.latitudeValue, this.longitudeValue]).addTo(map)
      .bindPopup(`<a href="${link}" target="_blank">${this.nameValue}</a>`)
      .openPopup();
  }
}
