import { Controller } from "stimulus"
import slick from 'slick-carousel' 

export default class extends Controller {
  static get targets() { return [ "container" ] }
  
  connect() {
    $(this.containerTarget).slick({
      infinite: false,
      slidesToShow: 6,
      slidesToScroll: 6,
      prevArrow: '<span class="lnr lnr-chevron-left"></span>',
      nextArrow: '<span class="lnr lnr-chevron-right"></span>',
      responsive: [
        {
          breakpoint: 768,
          settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false
          }
        }
      ]
    });
  }
  
  disconnect() {
    $(this.containerTarget).slick('unslick');
  }
}
