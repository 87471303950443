import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const maxDate = new Date()
    maxDate.setDate(maxDate.getDate() + 365 * 5)
    const minDate = new Date();
    if(this.element.dataset.minDaysOnDemand) {
      minDate.setDate(minDate.getDate() + parseInt(this.element.dataset.minDaysOnDemand));
    }
    const disabledWeekDays = this.element.dataset.disallowedDays.split(',').map(c => parseInt(c, 10));
    $(this.element).pickadate({
      min: minDate,
      max: maxDate,
      labelMonthNext: 'Próximo Mês',
      labelMonthPrev: 'Mês Anterior',
      // Título dos seletores de mês e ano
      labelMonthSelect: 'Selecione o Mês',
      labelYearSelect: 'Selecione o Ano',
      // Meses e dias da semana
      monthsFull: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      weekdaysFull: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      // Letras da semana
      weekdaysLetter: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      //Botões
      today: 'Hoje',
      clear: 'Limpar',
      disable: disabledWeekDays,
      close: 'Fechar',
      // Formato da data que aparece no input
      format: 'dd/mm/yyyy',
    });
  }
}

