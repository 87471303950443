import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return [
      "input",
      "fields",
      "text",
      "street",
      "suburb",
      "streetNumber",
      "city",
      "postcode",
      "state",
      "latitude",
      "longitude",
      "addressText",
      "placesField"
    ]
  }

  connect() {
    // Google Chrome autofill workaounrd
    const observerAutocompleteDisabler = new MutationObserver(() => {
      if (this.inputTarget.autocomplete == 'off') {
        this.inputTarget.autocomplete = `${Date.now()}-${parseInt(Math.random() * 100000)}`;
      }
    });

    observerAutocompleteDisabler.observe(this.inputTarget, {
      attributes: true,
      attributeFilter: ['autocomplete']
    });

    const options = {
      componentRestrictions: { country: "br" },
      fields: ["address_components", "geometry"],
      types: ["route", "administrative_area_level_2"],
    };
    const autocomplete = new google.maps.places.Autocomplete(this.inputTarget, options);
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      this.cleanFields();
      this.fieldsTarget.classList.remove("hidden");

      this.latitudeTarget.value = place.geometry.location.lat();
      this.longitudeTarget.value = place.geometry.location.lng();

      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "street_number": {
            this.streetNumberTarget.value = component.long_name;
            break;
          }

          case "route": {
            this.streetTarget.value = component.long_name;
            break;
          }

          case "sublocality_level_1": {
            this.suburbTarget.value = component.long_name;
            break;
          }

          case "administrative_area_level_2": {
            this.cityTarget.value = component.long_name;
            break;
          }

          case "administrative_area_level_1": {
            this.stateTarget.value = component.long_name;
            break;
          }

          case "postal_code": {
            this.postcodeTarget.value = component.long_name;
            break;
          }
        }
      };
    });
  }

  changeAddress(e) {
    e.preventDefault();
    this.addressTextTarget.classList.add('hidden');
    this.placesFieldTarget.classList.remove('hidden');
  }

  cleanFields() {
    this.latitudeTarget.value =
    this.longitudeTarget.value =
    this.streetNumberTarget.value =
    this.streetTarget.value =
    this.suburbTarget.value =
    this.cityTarget.value =
    this.stateTarget.value =
    this.postcodeTarget.value = '';
  }
}
