import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() { return [ "preview" ] }
  
  fileChanged(e) {
    const imgUrl = URL.createObjectURL(e.target.files[0]);
    var img = $('<img id="dynamic">');
    img.attr('src', imgUrl);
    this.previewTarget.innerHTML = `<img src="${imgUrl}" />`;
  }
}


