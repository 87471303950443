import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    setTimeout(this.destroy.bind(this), 6000)
  }

  destroy() {
    if (!this.element) return
    this.element.remove()
  }
}
