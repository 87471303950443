import { Controller } from "stimulus"

export default class extends Controller {
  static get values() { return { segments: Array, categoryFieldId: String, initialCategorySelected: String } }

  connect() {
    this.element.addEventListener('change', () => {
      this.clearPopulateCategoryField();
      if (!this.element.value) return;
      this.populateCategoryField(this.element.value);
    });

    if (this.element.value) {
      this.populateCategoryField(this.element.value);
      document.querySelector(this.categoryFieldIdValue).value = this.initialCategorySelectedValue;
    }
  }

  clearPopulateCategoryField() {
    document.querySelectorAll(`${this.categoryFieldIdValue} option`).forEach(c => c.remove());
  }

  populateCategoryField(segmentId) {
    this.clearPopulateCategoryField();
    const categoryField = document.querySelector(this.categoryFieldIdValue);
    const categories = this.segmentsValue.filter(s => s.id == segmentId)[0].categories;

    var option = document.createElement("option");
    option.value = '';
    option.text = '';
    categoryField.appendChild(option);

    categories.forEach((category) => {
      var option = document.createElement("option");
      option.value = category.id;
      option.text = category.name;
      categoryField.appendChild(option);
    });
  }
}
