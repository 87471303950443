import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() { return [ "container" ] }

  menuToggle() {
    this.containerTarget.classList.toggle('-translate-x-full');
  }

  modalToggle() {
    this.containerTarget.classList.toggle('translate-x-full');
  }
}