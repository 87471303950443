import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() { return [ "container" ] }

  connect() {
    $(this.containerTarget).slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      prevArrow: '<span class="lnr lnr-chevron-left"></span>',
      nextArrow: '<span class="lnr lnr-chevron-right"></span>',
    });
  }

  disconnect() {
    $(this.containerTarget).slick('unslick');
  }
}
