import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    document.addEventListener('orders/edit:recalculateSubTotal', this.calculateSubtotal.bind(this));
  }

  calculateSubtotal() {
    let subtotal = 0.0;
    [...document.querySelectorAll('[data-edit-order-item-target="total"]')]
      .map((el) => el.innerHTML)
      .map((el) => el.replace('R$ ', ''))
      .map(parseFloat)
      .map((el) => subtotal = subtotal + el);

    document.querySelector('#subtotal-value').innerHTML = `R$ ${subtotal.toFixed(2)}`;
    document.dispatchEvent(new Event('orders/show:recalculateTaxes'));
  }
}


